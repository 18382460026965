$(document).ready(function () {
    // ログイン情報の取得
    let mail_adder = localStorage.getItem('user.mail_adder');
    
    if (mail_adder) {
        $('#mail_adder').val(mail_adder);
        $('#remember').prop('checked', true);
    }
});

$(document).on('submit', '#login_form', function (e) {
    // イベント中止
    e.preventDefault();
    
    // ID情報を保持するチェック時
    let isCheck = $('#remember').prop('checked');
    if (isCheck) {
        localStorage.setItem('user.mail_adder', $('#mail_adder').val());
    } else {
        localStorage.removeItem('user.mail_adder');
    }
    
    let $form = $(this);
    $.ajax({
        url: $form.attr('action'),
        type: $form.attr('method'),
        data: $form.serializeArray(),
        dataType: 'json',
    }).done(function (data) {
        window.location.href = data.redirect;
    })
});

